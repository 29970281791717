.ClientsCard{
	width:100%;
	margin-top:5%;
}
.searchBlock{
  margin-top:20px;
  padding-bottom:20px;
  border-bottom:1px solid #e1e1e1;
}
.searchBlock Button{
  margin-top: 1rem;
  background-color:#3abbb7;
  width:9rem;
}
.searchBlock .typeaheadSelect input{
  margin-left:auto;
  margin-right:auto;
  width: 20rem;
  border-radius: 5px;
  border: 1px solid #3abbb7;
  font: inherit;
  padding-left: 0.5rem;
  background-color:#fff;
  color:#3abbb7;
}
.newClient{
	width:100%;
	display:flex;
	justify-content: right;
}
.newClientBtn{
	width:300px;
	background-color:#3abbb7;
	margin-bottom:10px;
	margin-right:10px;
	}

@media only screen and (max-width:991px){
	.ClientsCard{
		margin-top:20%;
	}
}
@media only screen and (max-width:575px){
	.ClientsCard{
		width:auto;
	}
}