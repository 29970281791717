.ordersCard{
  width:100%;
}

.checkedProduct{
  opacity:0.3;
}
td{
  text-align: left;
  color:#3abbb7;
}
.productRow:hover{
  background-color: #f4f4f4;
}
.quantityInput{
  width:3rem;
}
@media only screen and (max-width:500px){
  th,td{
      font-size:10px;
  }
}