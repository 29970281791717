.ordersCard{
  width:100%;
}
.orderRow:hover{
  background-color: #f5f5f5;
}
.proxeiroRow{
  background-color: #FDFD96;
}
.proxeiroRow:hover{
  background-color: #e8e889;
}
.greenRow{
  background-color: #46c42d;
}
.greenRow:hover{
  background-color: #3fb028;
}
.greenRow td,
.greenRow td p:not(.warning):not(.yellowWarning){
  color:#fff;
}
.redRow{
  background-color: #e61717;
}
.redrow:hover{
  background-color: #d41515;
}
.redRow td,
.redRow td p:not(.warning):not(.yellowWarning){
  color:#fff;
}

tr{
  cursor:pointer;
}
td{
  color:#3abbb7;

}
.warning{
  color: red;
}
.yellowWarning{
  color:orange;
}
.statusGrid{
  display:grid;
  grid-template-columns: 70% 30%;
  column-gap: 2px;
}
.updateStatusDate{
  font-size:9px;
  white-space: nowrap;
}
.historyIcon{
  font-size: 14px;
}
@media only screen and (max-width:500px){
  th,td{
      font-size:10px;
  }
}