.orderCard{
  width:100%;
  border:none;
  box-shadow: none;
}

.grid{
  display:grid;
  width:40%;
  grid-template-columns: 50% 50%;
  row-gap:30px;
  column-gap:10px;
  padding-bottom:40px;
  border-bottom:1px solid #707070;

}
.firstGrid{
  margin-top: 140px;
}
.grid + .grid{
  padding-top:40px;
  border-bottom:0px;
}

.firstButton{
  grid-column: 1/3;
}
.firstButton Button{
  background-color:#3abbb7;
}
.secondButton Button{
  background-color:#ff5f2e;
}
.thirdButton Button{
  background-color:#ffb500;
}

.secondaryFirstButton Button{
  background-color:#2d426d;
  width:100%;

}
.secondarySecondButton Button{
  background-color:#882507;
  width:100%;
}

.secondaryThirdButton Button{
  background-color:#800080;
}

.secondaryFourthButton Button{
  background-color:#008000;
}

span.badge {
  display:inline-block;
  visibility: hidden;
  color:#000;
  border-radius: 25px;
  border: 1px solid #000;
  font-weight: bold;
  position:relative;
  left:38%;
  bottom:80%;
  width:38px;
  height:38px;
  line-height:34px;
}
span.badge.active{
  visibility:visible;
}
span.greenBadge{
  background-color:#46c42d;
}
span.new{
  background-color:#D3EBF8;
}
span.seven{
  background-color: #ECC494;
}
span.ten{
  background-color: #FC8585;
}
.secondaryButton{
  display:inline-block;
}

.lateForSewing{
  width:60%;
  max-width: 1600px!important;
  min-width:60%;
}
.lateList{
  cursor:pointer;
  color:red;
}
@media only screen and (max-width:1200px){
    .grid{width:80%;}
}
@media only screen and (max-width:768px){
  .grid{width:95%}
}
@media only screen and (max-width:368px){
  .grid{
    font-size:10px
  }
}