.productModal > div{
  justify-content: center;
  width:80%;
}
.divider{
  margin-right:30px;
  margin-top:1.5rem;
  border-top:1px solid #e1e1e1;
}
.productListHeader{
  display:grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  color:#fff;
}
.productListHeader span{
  background-color:#3abbb7;
  text-align: center;

}
.productList{
  display:grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  color:#3abbb7;;
}
.productList span{
  text-align: center;
}
.deleted{
  opacity:0.3;
}
.emptyNotice{
  text-align: center;
}
.deleteButton{
  text-align: right;
  color:red;
  font-size:26px;
}
.confirmation:nth-child(1){
  margin-bottom:10px;
  background-color: #ccc;
}
.confirmation:nth-child(1):hover{
  margin-bottom:10px;
  background-color: #aaa;
}

.confirmation:nth-child(2){
  margin-bottom:10px;
  background-color: #3abbb7;
}
.confirmation:nth-child(2):hover{
  margin-bottom:10px;
  background-color: #34adaa;
}


.buttonGrid{
  display:grid;
  grid-template-columns: 50% 50%;
  column-gap: 10px;
  margin-top: 20px;
}