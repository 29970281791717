.login{
  background-color:#3abbb7;
  color:#fff;
  width:100%;
  padding:10px 0px
}
.credentials{
  margin-left:auto;
  margin-right:auto;
  width:60%;
  margin-top:20px;
}
.credentials label{
  margin-top:20px;
  width:100%;
  margin-right:0;
}
.credentials input{
  width:80%;
}
.loginButton Button{
  margin-top:20px;
  width:30%;
  background-color:#3abbb7;
  margin-bottom:40px;
}
