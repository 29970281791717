.singleModal{
  width:30rem;
  left: calc(50% - 15rem);
}

.singleModal h2{
  text-align: center;
}
.close{
  position:relative;
  font: inherit;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #3abbb7;
  color: #3abbb7;
  padding: 0.5rem 2rem;
  border-radius: 25px;
  margin:1rem;
  left: calc(50% - 3.5rem);
  }
.close:hover{
  background-color: #3abbb7;
  color:#fff;
}