.button {
  font: inherit;
  border-radius:10px;
  border:0px;
  background: #444;
  color: white;
  padding: 0.5rem 1rem;
  cursor: pointer;
  width:100%
}

.button:hover,
.button:active {
  background: #333;
}

.button:focus {
  outline: none;
}

.buttonLoad {
  opacity:0.5;
}