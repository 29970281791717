.card{
  background:white;
  border-radius: 5px;
  border:2px solid #e1e1e1;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  width:40%;
  max-width:1500px;
  margin-right:auto;
  margin-left:auto;
  margin-top:100px;
  color:#000;
}

@media only screen and (max-width:1200px){
  .card {
    width:80%;
    margin-top:20%;
  }
}
@media only screen and (max-width:768px){
  .card {
    width:99%;
    margin-top:100px;
    border:none!important;
    box-shadow: none!important;
  }
}
