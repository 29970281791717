.modal p,
.modal h2,
.modal label,
.modal span,
.modal option,
.modal select{
  color:#333;
  font-size:16px!important;
}
.close{
	font: inherit;
	cursor: pointer;
	background-color: #ff9700;
	border: 1px solid #ff9700;
	color: #fff;
	padding: 0.5rem 2rem;
	border-radius: 25px;
	margin-bottom:20px;
}