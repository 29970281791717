.searchBlock{
  display:grid;
  grid-template-columns: 90% 5.5%;
  grid-column-gap:0px;
  margin-top:20px;
  padding-bottom:20px;
}
.searchBlock label{
  margin-right: 1rem;
  color:#3abbb7;
  width:9rem;
}
.searchBlock select{
  width: 18rem;
  border-radius: 5px;
  border: 1px solid #3abbb7;
  font: inherit;
  padding-left: 0.5rem;
  background-color:#fff;
}
.searchBlock .add strong{
  color:#3abbb7;
  border:1px solid #3abbb7;
  border-radius:40px;
  cursor:pointer;
  padding: 20px 24px;
  position:relative;
  top:30%;
  right:60%;
}
.productLabel{
  display:grid;
  grid-template-columns: 25% 75%
}
.productLabel .dividerTitle{
  color:#3abbb7;
  margin-left:30px;
  line-height:3rem;
}
.divider{
  margin-right:30px;
  margin-top:1.5rem;
  border-top:1px solid #e1e1e1;
}
.productListHeader{
  display:grid;
  grid-template-columns: 20% 16% 16% 16% 16% 16%;
  color:#fff;
  margin-left:20px;
  margin-right:20px;
}
.productListHeader span{
  background-color:#3abbb7;
}
.productList{
  display:grid;
  grid-template-columns: 20% 16% 16% 16% 16% 16%;
  margin-left:20px;
  margin-right:20px;
  color:#3abbb7;;
}
.deleted{
  opacity:0.3;
}
.ButtonSet{
  margin: 40px 0px;
  display:grid;
  grid-template-columns:10% 39% 2% 39% 10%;
}

.cancelButton Button{
  grid-column:2;
  background-color: #ccc;
}
.nextButton Button{
  grid-column:4;
  background-color: #3abbb7;
}
@media only screen and (max-width:700px){
  .searchBlock .add strong{

  padding: 10px 12px;
  position:relative;
  top:5%;
  right:40%;
  font-size:10px;
}
}
@media only screen and (max-width:568px){
  .productListHeader,.productList{
    grid-template-columns: 20% 16% 16% 16% 16% 16%;
    margin-left:10px;
    margin-right:10px;
  }
  .searchBlock label{
    width:7rem;
    margin-right: 6rem;
  }
  .searchBlock select{
    width:12rem;

  }
}
