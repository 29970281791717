.AppHistoryCard{
	width:100%;
	margin-top:5%;
}
@media only screen and (max-width:991px){
	.AppHistoryCard{
		margin-top:20%;
	}
}
@media only screen and (max-width:575px){
	.AppHistoryCard{
		width:auto;
	}
}