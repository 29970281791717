.container{
  margin-top:100px;
  max-width:1500px;
  width:98%;
}
.filterList{
  display:grid;
  justify-content: center;
  grid-template-columns: 23% 22% 22%  29% 5%;
  border: 2px solid black;
  border-radius: 10px;
  margin:0 auto;
  padding:20px 5px;
}

.filterItem{
  cursor:pointer;
  border: 1px solid black;
  margin:0 10px;
  padding: 0 10px;
  border-radius:10px;
  text-align: left;
}
.filterItem select,
.filterItem option{
  color:#101010;
  border:none;
  background-color: #fff;
  width:100%; 
}
.filterItem input{
  border:none;
  width:80%;
}
.search{
  justify-self: end;
  text-align: center;
}
.boxGrid{
  margin-top:60px;
  display:grid;
  grid-template-columns: 29% 29% 29%;
  column-gap: 6.4%;
}

.saleBoxes > div{
  margin-bottom:30px;
  height:200px;
  max-height:200px;
  border:2px solid black;
  border-radius:20px;
}

.partialBox{
  display:flex;
  flex-direction:column;
}

.partialBox:first-child{
  background-color: #ffcf83;
}

.partialBox:nth-child(2){
  background-color: #7aff73;
}

.partialBox:nth-child(3){
  background-color: #41d9d4;
}

.boxTitle {
  color:#101010;
  margin: 8px auto 0 auto;
  padding-bottom:8px;
  border-bottom:3px solid black;
  width:80%;
}

.partialTotals{
  display:grid;
  grid-template-columns: 70% 35%;
}

.partialTotals .storePercentage p{
  text-align:right;
}

.partialTotals .storePercentage span{
  
  font-size:34px!important;
  border:1px solid black;
  white-space: nowrap;
}
.partialTotals,
.total {
  margin:auto;
  width:80%;
}
.partialTotals p{
  margin:0;
  color:#101010;
  text-align:left;
  white-space: nowrap;
}

.totalBox,
.manufacturerBox{
  height:660px;
  max-height:660px;
  border:2px solid black;
  border-radius:20px;
  display: flex;
  flex-direction: column;
}


.total p{
  margin:0;
  color:#101010;
  font-size: calc(30px + 3vmin);
  white-space: nowrap;
}

.manufacturerBox{
  
  background-color: #80b1ff;
  
}

.manufacturersList{
  overflow-y:scroll;
  scrollbar-width: none
}
.manufacturersListItem{
  display:grid;
  grid-template-columns: 70% 28%;
}

.manufacturersListItem p{
  color: #101010;
  font-weight:800;
  text-align: left;
}

.manufacturersListItem p:first-child{
  justify-self: start;
  margin-left:10px;
}
.manufacturersListItem p:nth-child(2){
  justify-self: end;
  align-self:end;
}

::-webkit-scrollbar-vertical {
  width: 0;
  height: 0;
}
@media only screen and (max-width:1470px) and (min-width:1201px){
  .partialTotals{
    grid-template-columns: 75% 25%;
    column-gap: 30px;
  }
  .partialTotals .storePercentage span{
    font-size:25px!important;
  }
}

@media only screen and (max-width:1200px) and(min-width:851px){
  .partialTotals{
    grid-template-columns: 75% 25%;
    column-gap: 20px;
  }
  .partialTotals div:not(.storePercentage) p{
    font-size: 14px;
  }
  .partialTotals .storePercentage span{
    font-size:18px!important;
  }
}
@media only screen and (max-width:1066px){
  .filterList{
    grid-template-columns: 26% 26% 26% 17% 5%;
  }
  .filterItem,
  .filterItem select,
  .filterItem option,
  .filterItem input{
    font-size:14px;
    white-space: nowrap;
  }
  .boxGrid{
    grid-template-columns: 32% 32% 32%;
    column-gap: 1.5%;
  }

  .totalBox{
    margin-bottom:30px;
  }
}

.pieChartContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);  /* Creates 3 equal columns */
  grid-gap: 20px;  /* Adds space between the pie charts */
  margin-bottom: 40px;
}

.pieChartContainer .pieChartItem {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


@media only screen and (max-width:850px){
  .filterList{
    grid-template-columns: 100%;
  }
  .filterItem{
    margin-bottom:15px;
  }
  .search{
  justify-self: center;
  text-align: center;
  width:100px;
  padding:10px 0;
  } 
  .filterItem input{
    width:90%;
  }
  .boxGrid{
    grid-template-columns: 100%;
    grid-column-gap: 0;
  }
}

