th{
	color:#fff;
}
th select{
	text-align: center;
}
.close{
	position:static;
	font: inherit;
	cursor: pointer;
	background-color: transparent;
	border: 1px solid #3abbb7;
	color: #3abbb7;
	padding: 0.5rem 2rem;
	border-radius: 25px;
	margin:1rem;
	}
.close:hover{
	background-color: #3abbb7;
	color:#fff;
}