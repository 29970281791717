.productListCard{
	width:100%;
	margin-top:5%;
	margin-bottom:5%;
}

.table{
	margin:auto;
	cursor:default;
}
/*.table th{
	min-width:1%;
	cursor:default;
}*/
.table td{
	cursor:default;
}
@media only screen and (max-width:991px){
	.productListCard{
		margin-top:20%;
	}
}
@media only screen and (max-width:575px){
	.productListCard{
		width:auto;
	}
}