.ordersCard{
  width:100%;
}
.orderRow:hover{
  background-color: #f5f5f5;
}
.orderRow td{
  text-align: center;
}
.greenRow{
  background-color: #46c42d;
}
.greenRow:hover{
  background-color: #3fb028;
}
.greenRow td,
.greenRow td p:not(.warning):not(.yellowWarning){
  color:#fff;
}
.redRow{
  background-color: #e61717;
}
.redrow:hover{
  background-color: #d41515;
}
.redRow td,
.redRow td p:not(.warning):not(.yellowWarning){
  color:#fff;
}

td{
  color:#3abbb7;

}
tr{
  cursor:pointer;
}
@media only screen and (max-width:500px){
  th,td{
      font-size:10px;
  }
}
