.ordersCard{
	width:100%;

}
.typeaheadSelect{
  font-size:11px;
}
.searchBlock{
  margin-top:20px;
  padding-bottom:20px;
  border-bottom:1px solid #e1e1e1;
}
.searchBlock Button{
  margin-top: 1rem;
  background-color:#3abbb7;
  width:9rem;
}
.searchBlock .typeaheadSelect input{
  margin-left:auto;
  margin-right:auto;
  width: 20rem;
  border-radius: 5px;
  border: 1px solid #3abbb7;
  font: inherit;
  padding-left: 0.5rem;
  background-color:#fff;
  color:#3abbb7;
}

.switchContainer{
  display:flex;
  padding:0 20px;
  margin-top:20px;
  margin-bottom:20px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
  margin-right:10px;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 24px;
  background-color: #ccc;
  transition: background-color 0.4s;
}

.slider:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  top: 2px;
  left: 2px;
  transition: transform 0.4s;
}

.switch input[type='checkbox'] {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch input[type='checkbox']:checked + .slider {
  background-color: #3abbb7;
}

.switch input[type='checkbox']:checked + .slider:before {
  transform: translateX(24px);
}

th{
	color:#fff;
}
@media only screen and (max-width:500px){
  th,td{
      font-size:10px;
  }
}