.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  background-color: #fff;
  color: cyan;
  display: flex;
  align-items: center;
  z-index: 10;
  border-bottom: 2px solid #707070;
}


.menuItem{
  margin-left:40px;
  text-decoration: none;
  color:#3abbb7;
  cursor:pointer;
}
button.xmls,button.xmls:hover{
  background-color: transparent!important;
  border: none!important;
}
.icon {
  width: 1.75rem;
  height: 1.75rem;
  position:absolute;
  right:20px;
  color:#3abbb7;
  min-width: 1.75rem;
  min-height: 1.75rem;
}
@media only screen and (max-width:768px){
  .header{
    overflow: scroll;
  }
  .icon{
    position:static;
    margin-left:15px;
  }
  .menuItem{
    margin-left:15px;
  }

}
@media only screen and (max-width:420px){
  .header{
    font-size:10px;
  }

}
@media print {
  .notPrintable{
    display:none;
  }
}