h2,p, select, option{
  color:#3abbb7;
}
p.hint{
  color:#999999;
}
.title{
  text-align: center;
}
.nowrap{
  white-space:nowrap;
}

.singleModal{
  width:30rem;
  left: calc(50% - 15rem);
}
.grid{
  display:grid;
  grid-template-columns: 50% 50%;
  column-gap:20px;
}

.typeaheadSelect input{
  width:12rem;
  margin-left:2.5rem;
}
.discountBtn{
  width: 10rem;
  background-color: #3abbb7;
  color: white;
  max-height:53px;
}
.discountBtn:hover {
  background-color: #34adaa;
  border-color: #34adaa;
  color: white;
}
.secondaryGrid{
  display:grid;
  grid-template-columns: 50% 44%;
  column-gap:10px;
  margin-bottom:20px
}
.loadingIcon{
  color:#3abbb7;
}
.searchBlock{
  margin-top:15px;
  margin-bottom:15px;
}
.searchBlock span{
  display: flex;
  align-items: center;
}
.calculation{
  display: flex;
  margin-bottom:15px;
}
.calculation p{
  width:10rem;
}
.calculation .btnStyle{
  margin-left:2.5rem;
  width:12rem;
}
div.searchBlock > span > label{
  color:#3abbb7;
  width:10rem;
}
.input label{
  width:20rem;
}
.textarea{
  margin-bottom:10px;
}
.textarea label{
  width:12.5rem;
  color: #3abbb7;
}
.textarea textarea{
  width:12rem;
  border-color: #3abbb7;
}

.searchBlock select{
  width: 12rem;
  border-radius: 5px;
  border: 1px solid #3abbb7;
  font: inherit;
  padding-left: 0.5rem;
  background-color:#fff;
  margin-left:2.6rem;
}

.actions {
  text-align: right;
  display:grid;
  grid-template-columns: 50% 50%;
  column-gap:10px;
  margin-top:20px;
  margin-bottom:20px;
}

.actions button {
  font: inherit;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #3abbb7;
  padding: 0.5rem 2rem;
  border-radius: 25px;
  margin-left: 1rem;
  width:90%;
}

.actions button:hover,
.actions button:active,
.btnStyle:hover {
  background-color: #34adaa;
  border-color: #34adaa;
  color: white;
}
.btnStyle{
  background-color: #3abbb7!;
  color: white;
  max-height:53px;
}
.actions .button--alt {
  color: #3abbb7;
}

.actions .button,
.btnStyle {
  background-color: #3abbb7;
  color: white;
}
.buttonLoad {
  opacity:0.5;
}
@media only screen and (max-width:1066px){
  .grid{
    display:block;
  }
}
@media only screen and (max-width:478px){
  .grid label, .searchBlock label{
    width:5rem!important;
  }
  .grid input, .searchBlock select{
    width:8rem!important;
  }
}