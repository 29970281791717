.confirmation:nth-child(1){
	margin-bottom:10px;
	background-color: #ccc;
}
.confirmation:nth-child(1):hover{
	margin-bottom:10px;
	background-color: #aaa;
}

.confirmation:nth-child(2){
	margin-bottom:10px;
	background-color: #3abbb7;
}
.confirmation:nth-child(2):hover{
	margin-bottom:10px;
	background-color: #34adaa;
}


.buttonGrid{
	display:grid;
	grid-template-columns: 50% 50%;
	column-gap: 10px;
}