th{
	color:#fff;
}
th select{
	text-align: center;
}
.productChange > div{
	width:70%;
}

.close{
	position:relative;
	font: inherit;
	cursor: pointer;
	background-color: transparent;
	border: 1px solid #3abbb7;
	color: #3abbb7;
	padding: 0.5rem 2rem;
	border-radius: 25px;
	margin:1rem;
	left:33%;
	align-self: center;
	}
.close:hover{
	background-color: #3abbb7;
	color:#fff;
}
.save{
	position:relative;
	font: inherit;
	cursor: pointer;
	background-color: transparent;
	border: 1px solid #3abbb7;
	color: #3abbb7;
	padding: 0.5rem 2rem;
	border-radius: 25px;
	margin:1rem;
	left:22%;
	align-self: center;
	}
.save:hover{
	background-color: #3abbb7;
	color:#fff;
}
.priceInput{
  display:flex;
  align-items: center;
}
.paymentTypeHeader{
  display:grid;
  grid-template-columns: 35% 65%;
  margin-top:20px;
}
.dividerTitle{
  color:#3abbb7;
  margin-left:30px
}
.divider{
  margin-right:30px;
  margin-top:1rem;
  border-top:1px solid #e1e1e1;
}
.serviceForm{
  margin-top:20px;

}
.serviceForm label{
  width:14rem;
}
.serviceForm input{
  width:6rem;
}