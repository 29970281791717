.ordersCard{
  width:100%;
}
.googleTasks{
  text-align: left;
  margin-left:20px;
}
.googleTasks Button{
  background-color: #3abbb7;
  width:300px;
  margin-bottom:20px;
}
td{
  color:#3abbb7;

}
th{
  color:#fff;
  cursor: auto;
}
th select{
  text-align: center;
}

@media only screen and (max-width:500px){
  th,td, span{
      font-size:10px!important;
      white-space: normal!important;
  }
}
