.ordersCard{
  width:100%;


}
.orders:hover{
  background-color: #f5f5f5;
}
.orders.seven{
  background-color: #ECC494;
}
.orders.seven:hover{
  background-color: #cfa87a;
}
.orders.ten{
  background-color: #FC8585;
}

.orders.ten:hover{
  background-color:#db6e6e;
}
td{
  color:#3abbb7;

}

@media only screen and (max-width:500px){
  th,td{
      font-size:10px;
  }
}