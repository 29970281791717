.close{
	position:absolute;
	right:10px;
	font: inherit;
	cursor: pointer;
	background-color: transparent;
	border: 1px solid #3abbb7;
	color: #3abbb7;
	padding: 0.5rem 2rem;
	border-radius: 25px;
	margin-left: 1rem;
}
.onOrder{
	font: inherit;
	cursor: pointer;
	background-color: #3abbb7;
	border: 1px solid #3abbb7;
	color: #fff;
	padding: 0.5rem 2rem;
	border-radius: 25px;
	margin-bottom:20px;
}