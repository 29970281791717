.close{
	position:static;
	font: inherit;
	cursor: pointer;
	background-color: transparent;
	border: 1px solid #3abbb7;
	color: #3abbb7;
	padding: 0.5rem 2rem;
	border-radius: 25px;
	margin:1rem;
	}
.close:hover{
	background-color: #3abbb7;
	color:#fff;
}

.grid{
	display:grid;
	grid-template-columns: 30% 70%;
	margin-left:20px;
	margin-right:20px;
}
.submitOrder{
  display:flex;
  justify-content: center;
}
.submitOrderButton{
  width:250px;
  margin-right:10px;
  background-color: #3abbb7;
}
.ypoloipo{
	font-weight:bold;
}
.deleted{
  opacity:0.3;
}
.newPayment{
  width: 10rem;
  background-color: #3abbb7;
  color: white;
  max-height:53px;
  white-space: nowrap;
}
.newPayment:hover {
  background-color: #34adaa;
  border-color: #34adaa;
  color: white;
}
.cancelPayment{
  width: 10rem;
  background-color: #ccc;
  color: white;
  max-height:53px;
  margin-right:5px;
}
.cancelPayment:hover {
  background-color: #bbb;
  border-color: #bbb;
  color: white;
}
.priceQuantity p{
  color:#101010;
}
.singleItemPrice{
  font-size: 10px;
  white-space: nowrap;
}
.strikeThrough{
  text-decoration: line-through;
}
@media only screen and (max-width:900px){
	.grid{
		display:block;
	}
}