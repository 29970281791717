.searchBlock{
  margin-top:20px;
  padding-bottom:20px;
  border-bottom:1px solid #e1e1e1;
}
.searchBlock Button{
  margin-top: 1rem;
  background-color:#3abbb7;
  width:9rem;
}
.searchBlock .typeaheadSelect input{
  margin-left:auto;
  margin-right:auto;
  width: 20rem;
  border-radius: 5px;
  border: 1px solid #3abbb7;
  font: inherit;
  padding-left: 0.5rem;
  background-color:#fff;
  color:#3abbb7;
}
.grid{
  display:grid;
  grid-template-columns: 50% 50%;
  margin-top:20px;
}
.proxeiro{
  display:flex;
}
.proxeiroToggle{
  color: #3abbb7;
  margin-left:20px;
}
.proxeiroToggle label{
  margin-right:20px;
}
.vip {
  display: inline-flex;
  align-items: center;
  margin-bottom: 0.5rem;
  color: #3abbb7;
}
.vip label{
  margin-right: 10px;
  color: #3abbb7;
  width: 12rem;
  display:inline-block;
}
.vip input{
  width: 12rem;
  display:inline-block;
  font: inherit;
}
.timologio{
  display:grid;
  grid-template-columns: 40% 60%;
  column-gap: 10px
}
.timologio label{
  width:16rem;
  color: #3abbb7;
}
.timologio input{
  width:1rem;
}
.timologio .divider{
  margin-right:30px;
  margin-top:1rem;
  border-top:1px solid #e1e1e1;
}
.ButtonSet{
  margin: 40px 0px;
  display:grid;
  grid-template-columns:10% 39% 2% 39% 10%;
}

.cancelButton Button{
  grid-column:2;
  background-color: #ccc;
}
.nextButton Button{
  grid-column:4;
  background-color: #3abbb7;
}
.proxeiroButton Button{
  background-color: orange;
  width:40%;
  margin-bottom:30px;
}
@media only screen and (max-width:1066px){
  .grid,.timologio{
    display:block;
  }
  .clientCard{
    display:flex;
    justify-content: center;
  }
}
@media only screen and (max-width:540px){

  .searchBlock label, .searchBlock select,.timologio label{
    width:10rem;
  }
  .grid label{
    width: 5rem;
  }
  .grid input{
    width:7rem;
  }
}