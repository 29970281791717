.input {
  display: inline-flex;
  align-items: center;
  margin-bottom: 0.5rem;
  color:#3abbb7;
}

.input label {
  margin-right: 10px;
  color:#3abbb7;
  width:10rem;
}

.input input {
  width: 12rem;
  border-radius: 5px;
  border: 1px solid #3abbb7;
  font: inherit;
/*  padding-left: 0.5rem;*/
}

.vertInput{
  white-space: nowrap;
}