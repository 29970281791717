.singleModal{
	position:fixed;
	left:calc(50% - 300px);
	width:600px;
}
.cancelButton{
	position:absolute;
	right:10px;
	top:0;
}
.categoryHeader{
	background-color: #3abbb7;
	margin-bottom: 10px;
}
.categoryHeader p{
	color:#fff;
}
.categoryGrid{
	display:grid;
	grid-template-columns: 80% 20%;
}
.categoryCheckbox{
	margin-right:4px;
}
.quantityInput{
	width:4rem;
	margin-bottom:10px;
}
.categoryList{
	list-style-type:none;
	color: #3abbb7;
}

.buttons{
	display:grid;
	grid-template-columns: 50% 50%;
	column-gap:10px;
}
.buttons Button{
	margin-bottom: 20px;
}
.confirmButton{
	background-color: #3abbb7;
}

@media only screen and (max-width:599px){
	.singleModal{
		left:calc(50% - 150px);
		width:300px;
	}
	.categoryGrid{
		font-size:12px;
	}

	.categoryHeader{
		display:none;
	}

	.quantityInput{
		width:2rem;
		height:1.5rem;
	}
}

