.loadingSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
}

.spinner {
  border: 8px solid #9cdddb;
  border-top: 8px solid #3abbb7;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}