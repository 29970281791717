.grid{
  display:grid;
  grid-template-columns: 50% 50%;
  margin-top:20px;
}
.timologio{
  display:grid;
  grid-template-columns: 40% 60%;
  column-gap: 10px
}
.grid label{
  margin-left:10px;
}
.timologio label{
  width:16rem;
  color: #3abbb7;
}
.timologio input{
  width:1rem;
}
.timologio .divider{
  margin-right:30px;
  margin-top:1rem;
  border-top:1px solid #e1e1e1;
}
.ButtonSet{
  margin: 40px 0px;
  display:grid;
  grid-template-columns:10% 39% 2% 39% 10%;
}

.cancelButton Button{
  grid-column:2;
  background-color: #ccc;
}
.nextButton Button{
  grid-column:4;
  background-color: #3abbb7;
}
@media only screen and (max-width:1066px){
  .grid,.timologio{
    display:block;
  }
  .clientCard{
    display:flex;
    justify-content: center;
  }
}
@media only screen and (max-width:540px){

  .searchBlock label, .searchBlock select,.timologio label{
    width:10rem;
  }
  .grid label{
    width: 5rem;
  }
  .grid input{
    width:7rem;
  }
}