/* Container to center the spinner */
.loadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f8f8;
}

/* Loading spinner styles */
.loadingSpinner {
  width: 80px;
  height: 80px;
  border: 12px solid #f3f3f3; /* Light grey */
  border-top: 12px solid #ff9700; /* Blue */
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
}

/* Spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Loading text */
.loadingContainer p {
  margin-top: 20px;
  font-size: 18px;
  color: #333;
}
