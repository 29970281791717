.nonInteractable {
  pointer-events: none; /* Disable clicking on non-modal content */
  display:none; 
}
.newProduct p,
.newProduct h2,
.newProduct label,
.newProduct span,
.newProduct option,
.newProduct select{
  color:#333;
  font-size:16px!important;
}

.newProduct input{
  border-color: #333;
}

.newProduct select{
  width:80%;
  height:38px;
  
}



.searchBlock{
  display:grid;
  grid-template-columns: 90% 5.5%;
  grid-column-gap:0px;
  margin-top:20px;
  padding-bottom:20px;
}
.searchBlock label{
  margin-right: 1rem;
  color:#333;
  width:9rem;
}
.searchBlock select{
  width: 18rem;
  border-radius: 5px;
  border: 1px solid #333;
  font: inherit;
  padding-left: 0.5rem;
  background-color:#fff;
}
.add strong{
  color:#ff9700;
  border:1px solid #ff9700;
  border-radius:40px;
  cursor:pointer;
  padding: 10px 14px;
}
.productLabel{
  display:grid;
  grid-template-columns: 50% 50%
}
.productLabel .dividerTitle{
  color:#333;
  margin-left:30px;
  line-height:3rem;
  font-size:16px;
}
/*.divider{
  margin-right:30px;
  margin-top:1.5rem;
  border-top:1px solid #e1e1e1;
}*/
.productListHeader{
  display:grid;
  grid-template-columns: 25% 25% 25% 25%;
  color:#fff;
  margin-left:20px;
  margin-right:20px;
}
.productListHeader span{
  background-color:#ff9700;
}
.productList{
  display:grid;
  grid-template-columns: 25% 25% 25% 25%;
  margin-left:20px;
  margin-right:20px;
  color:#333;
  border: 1px solid #ff9700;
}
.deleted{
  opacity:0.3;
}
.ButtonSet{
  margin: 40px 0px;
  display:grid;
  grid-template-columns:10% 39% 2% 39% 10%;
}

.cancelButton Button{
  grid-column:2;
  background-color: #ccc;
}
.nextButton Button{
  grid-column:4;
  background-color: #ff9700;
}



@media only screen and (max-width:568px){
  .productListHeader,.productList{
    margin-left:10px;
    margin-right:10px;
  }
  .searchBlock label{
    margin-right: 6rem;
  }
  .searchBlock select{
    width:12rem;
    height:38px;
  }
}

@media (max-width: 480px) {
  .productListHeader {
    grid-template-columns: repeat(1, 1fr);
    font-size: 10px;
  }

  .productList {
    grid-template-columns: repeat(1, 1fr);
    font-size: 9px;
  }

  .ButtonSet {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .searchBlock {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .add {
    font-size: 20px;
  }

  .cancelButton, .nextButton {
    width: 100%;
  }
}
