.tabs {
  display:grid;
  grid-template-columns:50% 50%;
}
.tabs p {
    color:#e1e1e1;
    border:1px solid #e1e1e1;
    padding: 20px 0px;
    margin: 0px;
}
.tabs p.selected{
  color:#fff;
  background-color:#ff9700;
}
.tabs p.previous{
  color:#e1e1e1;
  border-right:0px;
}

p.previous + p.selected{
  border-left:0px;
}
