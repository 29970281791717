.ordersCard{
  width:100%;
 

}
td{
  color:#3abbb7;

}

.rafeioDiv{
  display:grid;
  grid-template-columns: 80% 20%;
}
.rafeioInput{
  margin-top:5px;
  width:8rem;
}
.rafeioInput label,
.rafeioText{
  margin-right:0!important;
  width:6rem;
  font-size:12px;
}
.rafeioInput input{
  width: 6rem!important;
  border-radius: 5px;
  border: 1px solid #3abbb7;
}
.rafeioSaveBtn{
  margin-top:2rem;
  margin-left:5px;
}
.tailorSelect{
  max-height: 26px;
  margin-top: 26px;
  font-size:11px;
}
.products:hover{
  background-color: #f5f5f5;
}
.products.seven{
  background-color: #ECC494;
}
.products.seven:hover{
  background-color: #cfa87a;
}
.products.ten{
  background-color: #FC8585;
}

.products.ten:hover{
  background-color:#db6e6e;
}
.sewingPriceInput{
  width:5rem;
}
@media only screen and (max-width:500px){
  th,td{
      font-size:10px;
  }
}