.ordersCard{
  width:99%;
  padding-left:10px;
  padding-right:10px;
}

thead tr{
  background-color:#3abbb7;

}
th{
  color:#fff;
}
.list{
  margin-bottom:20px;
}
.selectionContainer{
  display:flex;
  margin-bottom:20px;
  column-gap: 20px;
}
.generalCheckbox{
  display:inline-flex;
  width:99%;
  justify-content: end;
}

.generalCheckbox input{
  margin-left:5px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
  margin-right:10px;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 24px;
  background-color: #ccc;
  transition: background-color 0.4s;
}

.slider:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  top: 2px;
  left: 2px;
  transition: transform 0.4s;
}

.chooseCategoriesButton,
.printButton{
  background-color: #3abbb7;
  width:auto;
  display:block;
  margin-left:10px;
  margin-bottom:10px;
  font-size:calc(10px + 1vmin);
}


@media only screen and (max-width:660px){
  .selectionContainer{
    display:block;
  }
  .selectionContainer button{
    margin-left:0px;
  }
  .selectionContainer select{
    display:block;
    margin-bottom:10px;
  }
  .printButton{
    margin-left:0;
  }
}

#printable{
  display:none;
  visibility: hidden;
}
@media print {
  #notPrintable{
    display:none;
    visibility: hidden;
  }
  .body * {
    margin-top:10px;
    position:absolute;
    page-break-after:avoid;
  }
  
  #printable,
  #printable * {
    visibility: visible;
    color:#101010;
    page-break-after: auto;
  }
  #printable{
    position:static;
    top:0;
    left:0;
    display:block;
    margin:0 20px;
    text-align: left;
  }
  .printableGrid{
    display:grid;
    grid-template-columns: 50% 25% 25%;
    column-gap:20px;
  }
  @page {
    size: A4;
    margin: 0mm;
    padding: 0mm;
    border: none;
  } 
}