.viewTable th{
  cursor:auto;
}
.priceQuantity p{
  color:#101010;
}
.wholesalePrice div{
  display:grid;
  grid-template-columns: 34% 33% 33%;
}
.wholesalePrice p,
.wholesalePrice p input{
  color:#ddd!important;
  font-size: 14px;

}
.wholesalePrice p label{
  margin-right:10px;
  width:7rem;
}
.wholesalePrice p input{
  width:3rem;
  border-color:#ddd;
}
.wholesaleSaveContainer{
  min-height: 45px;
}
.wholesaleSave{
  width:5rem;
  background-color:#3abbb7;
}
.singleItemPrice{
  font-size: 10px;
  white-space: nowrap;
}
.strikeThrough{
  text-decoration: line-through;
}
.close{
	position:static;
	font: inherit;
	cursor: pointer;
	background-color: transparent;
	border: 1px solid #3abbb7;
	color: #3abbb7;
	padding: 0.5rem 2rem;
	border-radius: 25px;
	margin:1rem;
	}
.close:hover{
	background-color: #3abbb7;
	color:#fff;
}
.submitOrder{
  display:flex;
  justify-content: right;
}
.submitOrderButton{
  width:250px;
  margin-right:10px;
  background-color: #3abbb7;
}
.grid{
	display:grid;
	grid-template-columns: 28% 72%;
	margin-left:20px;
	margin-right:20px;
}

.ypoloipo{
  font-weight:bold;
}
.arnhtikoYpoloipo{
  color:red;
}
.paymentListItem{
  text-align: left;
}
.deleted{
  opacity:0.3;
}
.searchBlock{
  display:grid;
  grid-template-columns: 90% 5.5%;
  grid-column-gap:0px;
  margin-top:20px;
  padding-bottom:20px;
}
.searchBlock label{
  margin-right: 1rem;
  color:#3abbb7;
  width:9rem;
}
.searchBlock select{
  width: 18rem;
  border-radius: 5px;
  border: 1px solid #3abbb7;
  font: inherit;
  padding-left: 0.5rem;
  background-color:#fff;
}
.searchBlock .add strong{
  color:#3abbb7;
  border:1px solid #3abbb7;
  border-radius:40px;
  cursor:pointer;
  padding: 20px 24px;
  position:relative;
  top:30%;
  right:60%;
}
.recalc{
  background-color: #3abbb7;
  color: white;
  max-height:53px;
  margin-bottom:10px;
}
.recalc:hover {
  background-color: #34adaa;
  border-color: #34adaa;
  color: white;
}
.newPayment{
  width: 10rem;
  background-color: #3abbb7;
  color: white;
  max-height:53px;
  margin-bottom:10px;
  white-space: nowrap;
}
.newPayment:hover {
  background-color: #34adaa;
  border-color: #34adaa;
  color: white;
}
.cancelPayment{
  width: 10rem;
  background-color: #ccc;
  color: white;
  max-height:53px;
  margin-right:5px;
  margin-bottom:10px;
}
.cancelPayment:hover {
  background-color: #bbb;
  border-color: #bbb;
  color: white;
}
.threeDays{
  background-color: yellow;
  color:#101010;
}
.sixDays{
  background-color: red;
  color:#fff;
}
.selectGrid{
  display:grid;
  grid-template-columns: 70% 30%;
  column-gap: 2px;
}
.updateStatusDate{
  font-size:9px;
  align-self: end;
}
.statusSelect
.tailorSelect{
  width:8rem;
}
.tailorSelect{
  max-height: 26px;
  margin-top: 26px;
  font-size:11px;
}
.rafeioDiv{
  display:grid;
  grid-template-columns: 80% 20%;
}

.rafeioInput{
  margin-top:5px;
  width:8rem;
}
.rafeioInput label,
.rafeioText{
  margin-right:0!important;
  width:6rem;
  font-size:12px;
}
.rafeioInput input{
  width: 6rem!important;
  border-radius: 5px;
  border: 1px solid #3abbb7;
}
.rafeioSaveBtn{
  margin-top:2rem;
  margin-left:5px;
}

.merikosDiv label{
  margin-right:5px;
  color:#3abbb7;
  font-size:12px;
}

.profitText{
  font-size: 11px;
}
@media only screen and (max-width:900px){
	.grid{
		display:block;
	}
  .paymentListItem{
    text-align: center;
  }

  .wholesalePrice div{
  display:grid;
  grid-template-columns: 50% 50%;
}

  .wholesalePrice p,
  .wholesalePrice p input{
    font-size: 10px;

  }
  .wholesalePrice p label{
    margin-right:3px;
    width:4.5rem;
  }
  .wholesalePrice p input{
    width:3rem;
  }
}
@media only screen and (max-width:669px){
  .viewTable{
    margin-left:3rem;
  }
}
@media only screen and (max-width:630px){
  .viewTable{
    margin-left:5rem;
  }
}
@media only screen and (max-width:500px){
  .viewTable{
    margin-left:15rem;
  }
}
@media only screen and (max-width:390px){
  .viewTable{
    margin-left:20rem;
  }
}


.printable,
#printableOrderAdditionalProducts,
.printableFooter{
  display:none;
  visibility: hidden;
}
@media print {
/*  :not(.printable, .printable *)  {
    visibility: hidden;
  }*/
  .body{
    margin: 0;
    padding: 0;
  }
  .notPrintable{
    display:none;
    visibility: hidden;
    max-height:0;
  }

  .printable,
  .printable * {
    visibility: visible!important;
    color:#101010;
    overflow:initial!important;
  }
  .printable{
    display:block!important;
    width:100%;
    text-align: left;
  }
  #printableClient{
    margin:0;
    display:grid;
    grid-template-columns: 32% 68%;
    column-gap:20px;
  }
  #printableClientLeft{
    position:relative;
    top:-5mm;
  }
  #printableClientRight{
    justify-self: center;
  }
  #printableClient p{
    line-height: 1em;
    margin:5px;
    max-height: 3em; 
    overflow: hidden;
  }

  .banner{
    -webkit-print-color-adjust:exact !important;
    print-color-adjust:exact !important;
    text-align: center;
    background: #FFA500;
    padding-left:5px;
    padding-right:5px;
    margin-bottom:0px;
    white-space: nowrap;
    width:100%;
    font-size: 16px;
  }
  div.banner p{
    margin-top:5px;
    width:100%;
    color:#fff!important;
  }
  #printableOrder{
/*    display:grid;*/
    grid-template-columns: 45% 55%;
    margin: 0 20px;
/*    border:4px solid #FFA500;
    border-right:0; */
/*    min-height:170mm;*/
/*    max-height:170mm;*/
  }
  #printableOrderFinancial{
    border:4px solid #FFA500;
  }

  #printableOrderFinancial .banner p{
    margin-top:0;
  }
  #printableOrderInfo{
/*    border-right:4px solid #FFA500;*/
/*    min-height:170mm;*/
/*    max-height:170mm;*/
  }

  #printableOrderInfo .productItemContainer{
    page-break-inside: avoid;
    border-top: 1px solid #FFA500;
  }
/*  #printableOrderInfo .productItemContainer:nth-child(13){
    margin-top:100px;
  }*/
  #printableOrderInfo .productItem{
    display:grid;
    grid-template-columns: 85% 15%;
    font-size:14px;
    width:100%;
  }

  #printableOrderInfo .productNotes{
    font-size:10px;
    border-bottom: 2px solid #ddd;
    width:100%;
  }
  

  .printPayment{
    display:grid;
    grid-template-columns: 35% 35% 30%;
  }
  .additionalServices span{
    margin-bottom:1rem;
    white-space: nowrap;
  }

  .detailedPayments{
    line-height:8px;
    font-size: 16px;
    color:#555;
  }
  .einteriorInfo{
    background-color: #FFA500;
    text-align: center;
  }
  .einteriorContact{
    margin-top:30px;
  }
  .einteriorContact p{
    color:#fff;
    font-weight:800;
    font-size:24px;
    margin:0;
  }
  .printableFooter{
    display:block;

  }
  .printablePaymentBasic p{
    margin-left:10px;
  }
  .printablePaymentAnalysisInfo{
    border-left:4px solid #FFA500;
  }
  .printablePaymentAnalysisInfo p{
    margin-left:10px;
  }
  .tracking{
    display:inline-block!important;
    padding-bottom:10px;
    border-left:4px solid #FFA500;
    border-right:4px solid #FFA500;
    width:100%;
    white-space: nowrap;
    font-size:16px;
    text-align:center;
  }
  .bold{
    font-weight: 400;
  }


  #printableOrderAdditionalProducts{
    display:block!important;
    padding:20px;
    text-align: left;
    width:100%;
  }
  #printableOrderAdditionalProducts .productsGroup{
    position:relative;
    top:15px;
    border:3px solid #FFA500;
    display:block!important;
    page-break-before: always!important;
    min-height:240mm!important;
    margin-top:10px!important;
    box-sizing: border-box;
  }

  #printableOrderAdditionalProducts .productsGroup:last-child{
    page-break-after: avoid;
  }



  #printableOrderAdditionalProducts .productItem{
    display:grid;
    grid-template-columns: 55% 25% 20%;
    font-size:14px;
    width:100%;
  }

  #printableOrderAdditionalProducts .productNotes{
    font-size:10px;
    border-bottom: 2px solid #ddd;
    padding-bottom:5px;
    width:100%;
    visibility:visible;
  }
  .bottomText{
    border:4px solid #FFA500;
    display:grid;
    grid-template-columns: 40% 60%;
    padding:0;
    margin:0;
    width:100%;
  }
  .bottomText .disclaimer{
    font-size:10px;
  }
  .bottomText .bankInfo{
    border-left:4px solid #FFA500;
    line-height: 1.3rem;
    font-size:16px;
  }
  .bottomText .bankInfo p{
    margin:0;
  }

  .printableFooter *{
    visibility:visible;
  }
/*  .printableFooter{
    left:0;
    position:absolute;
  }*/
  .printableFooter{
    margin: 0 20px;

  }
  .printableOffer{
    position:absolute;
    font-size:30px;
    transform: translate(87mm,-5mm);
  }

  .printableVipContainer{
    display:flex;
    width:100%;
    justify-content: center;
  }
  .printableVip{
    color:#FFA500;
    font-size:26px;
  }

  .printableVip svg, .printableVip path {
    color: #FFA500 !important;

  }
  @page {
  size: A4;
  margin: 0.2in 0.1in !important;
  padding: 0mm!important;
  border: none;
}

}

