.newProduct p,
.newProduct h2,
.newProduct label,
.newProduct span,
.newProduct option,
.newProduct select{
  color:#333;
  font-size:16px!important;
}

.newProduct input{
  border-color: #333;
}

.newProduct select{
  width:80%;
  height:38px;
  
}

.priceFixed{
  position:fixed;
  top:10px;
  background-color: #ff9700;
  color:white!important;
  width:100%;
  padding:10px 0;
}

.hint{
  color:#ff9700!important;
}

.grid{
  display:flex;

}


.discountBtn{
/*  width: 10rem;*/
  background-color: #ff9700;
  color: white;
  max-height:53px;
}
.discountBtn:hover {
  background-color: #FF9000;
  border-color: #FF9000;
  color: white;
}

.loadingIcon{
  color:#ff9700;
}
.searchBlock{
  margin-top:15px;
  margin-bottom:15px;
}
.searchBlock span{
  display: flex;
  align-items: center;
  flex-direction:column;
}
.calculation{
  display: flex;
  margin-bottom:15px;
}
.calculation p{
  width:10rem;
}
.calculation .btnStyle{
  margin-left:2.5rem;
  width:12rem;
}
div.searchBlock > span > label{
  color:#333;
}
.input label{
  width:20rem;
}
.textarea{
  display: flex;
  flex-direction: column; 
  margin-bottom: 1em; 
  align-items: center;
}
.textarea label{

  color: #333;
}
.textarea textarea{
  border-color: #333;
/*  max-width:90%;*/

}

.searchBlock select{
  border-radius: 5px;
  border: 1px solid #333;
  font: inherit;
  padding-left: 0.5rem;
  background-color:#fff;
}

.actions {
  text-align: right;
  /*display:grid;
  grid-template-columns: 50% 50%;*/
/*  column-gap:10px;*/
  margin-top:20px;
  margin-bottom:20px;
  width:100%;
}

.actions button {
  font: inherit;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #ff9700;
  padding: 0.5rem 2rem;
  border-radius: 25px;
  margin-bottom: 10px;
}

.actions button:hover,
.actions button:active,
.btnStyle:hover {
  background-color: #FF9000;
  border-color: #FF9000;
  color: white;
}
.btnStyle{
  background-color: #ff9700!;
  color: white;
  max-height:53px;
}
.actions .button--alt {
  color: #ff9700;
}

.actions .button,
.btnStyle {
  background-color: #ff9700;
  color: white;
}
.buttonLoad {
  opacity:0.5;
}
