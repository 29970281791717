.grid{
	display:grid;
	grid-template-columns: 15% 15% 15% 15% 15% 15%;
}
.grid label{
	display:none;
}
.grid input{
	color:#3abbb7;
	margin-left:auto;
	margin-right:auto;
	background-color:#e9e9ed;
	border-color:#101010;
}
.grid .filter{
	align-self:center;
}
.clearButton{
	margin-top:10px;
}
.added{
	background-color:#90ff87;
}
.removed{
	background-color:#ffacac;
}
.singleClient:hover{
	background-color: #f5f5f5;
}
td{
	color:#101010;
}

@media only screen and (max-width:930px){
	th,td{
		font-size:10px;
	}
	.grid{
		font-size:10px;
		grid-template-columns: 50% 50% 
	}
	.grid select{
		width:3rem;
		margin-bottom:10px;
	}
	.filter button{
		display:block;
		width:50%!important;
		margin-left:auto;
		margin-right:auto;
	}

}
@media only screen and (max-width:575px){
	table{
		overflow:scroll;
	}
	th,td{
		font-size:8px;
	}
}