.newProduct p,
.newProduct h2,
.newProduct select,
.newProduct option,
.newProduct label,
.newProduct span{
  color:#333;
  font-size:16px!important;
}

.newProduct input{
  border-color: #333;
}
.newProduct select{
  width:80%;
  height:38px;
}
.serviceCost{
  display:grid;
  grid-template-columns: 45% 55%;
  margin-top:20px;
}
.priceInput{
  display:flex;
  align-items: center;
}
.paymentTypeHeader{
  display:grid;
  grid-template-columns: 35% 65%;
  margin-top:20px;
}
.dividerTitle{
  color:#ff9700;
  margin-left:30px
}
.divider{
  margin-right:30px;
  margin-top:1rem;
  border-top:1px solid #e1e1e1;
}
.serviceForm{
  margin-top:20px;

}
.serviceForm label{
  width:14rem;
}
.serviceForm input{
  width:6rem;
}
.paymentForm{
  margin-top:20px;
  /*display:grid;
  grid-template-columns: 34% 33% 33%;
  grid-column-gap:20px*/
}
.paymentForm .paymentType label{
  margin-right:0;
}
.paymentForm .paymentType input{
  width:6rem;
}
.paymentForm .total label{
  position:relative;
  left:30px;
  width:12rem;
}
.paymentForm .prepay label{
  position:relative;
  width:9rem;
  right:10px;
}
.paymentForm .total input,
.paymentForm .prepay input{
  width:8rem;
}
.prepay label{display:block}
/*.ButtonSet{
  margin: 40px 0px;
  display:grid;
  grid-template-columns:10% 39% 2% 39% 10%;
}*/

.cancelButton Button{
  grid-column:2;
  background-color: #ccc;
  margin-bottom:10px;
}
.nextButton Button{
  grid-column:4;
  background-color: #ff9700;
  margin-bottom:10px;
}
@media only screen and (max-width:1066px){
  .paymentForm,.serviceForm{
    display:block;
  }
  .total .prepay{
    margin-left:auto;
    margin-right:auto;
  }
  .paymentForm .total label,.paymentForm .prepay label{
    display:inline-block;
    margin-right:2.6rem;
    position:static;
    width:10rem;
  }
  .paymentForm .total input,.paymentForm .prepay input{
    width:6rem;
    display:inline-block;
  }
}