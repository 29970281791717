.ordersCard{
  width:100%;
 

}
.orderRow:hover{
  background-color: #f5f5f5;
}

td{
  color:#3abbb7;

}

@media only screen and (max-width:500px){
  th,td{
      font-size:10px;
  }
}